import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Header from "../components/header"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/SEO/seo"

const ArticlesListPage = props => {

  const { data, pageContext } = props
  const { allPrismicArticle } = data
  const { currentPage, limit, skip, numPages } = pageContext

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/blog/" : "/blog/" + (currentPage - 1).toString() + "/"
  const nextPage = "/blog/" + (currentPage + 1).toString()

  return (
    <Layout>
      <SEO
        title="Conseils, fiscalité, astuces pour les entrepreneurs"
        desc="Toutes les informations sur la réglementation et astuces pour gérer au mieux votre entreprise."
      />
      <Header
        title="Actualité des entrepreneurs"
        isBig={true}
        subtitle="Toutes les informations sur la réglementation et astuces pour gérer au mieux votre entreprise."
      />
      <section className="section">
        <Container>
          <Row>
            {/* blog box */}
            {
              allPrismicArticle.edges.map((blog, key) =>
                <Col lg="4" md="6" className="mb-4 pb-2" key={key} name="blog">
                  <Card className="blog rounded border-0 shadow overflow-hidden">
                    <div className="position-relative">
                      <GatsbyImage
                        image={blog.node.data.image.localFile.childImageSharp.gatsbyImageData}
                        alt={blog.node.data.image.alt}
                        className="rounded-top card-img-top" />
                      <div className="overlay rounded-top bg-dark"></div>
                    </div>
                    <CardBody className="content">
                      <h5><Link to={"/blog/" + blog.node.uid + "/"}
                                className="card-title title text-dark">{blog.node.data.title}</Link></h5>
                      <p className="text-muted">{blog.node.data.subtitle}</p>
                      <div className="post-meta d-flex justify-content-between mt-3">
                        <Link to={"/blog/" + blog.node.uid + "/"} className="text-muted readmore">Lire la suite <i
                          className="mdi mdi-chevron-right"></i></Link>
                      </div>
                    </CardBody>
                    <div className="author">
                      <small className="text-light date"><i
                        className="mdi mdi-calendar-check"></i> {blog.node.first_publication_date}</small>
                    </div>
                  </Card>
                </Col>
              )
            }

            {numPages > 1 && (
              <Col xs="12">
                <ul className="justify-content-center mb-0 pagination">
                  {!isFirst && (
                    <li className="page-item">
                      <Link to={prevPage} className="page-link" aria-label="Précédent"><span
                        aria-hidden="true">Précédent</span><span
                        className="sr-only">Précédent</span></Link>
                    </li>
                  )}
                  {Array.from({ length: numPages }, (_, i) => (
                    <li className={i + 1 === currentPage ? "page-item active" : "page-item"}>
                      <Link to={`/blog/${i === 0 ? "" : i + 1}`} className="page-link">{i + 1}</Link>
                    </li>
                  ))}
                  {!isLast && (
                    <li className="page-item">
                      <Link to={nextPage} className="page-link" aria-label="Suivant"><span
                        aria-hidden="true">Suivant</span><span
                        className="sr-only">Suivant</span></Link>
                    </li>
                  )}
                </ul>
              </Col>
            )}

          </Row>
        </Container>
      </section>
    </Layout>
  );
}

ArticlesListPage.propTypes = {
  data: PropTypes.shape({
    allPrismicArticle: PropTypes.object.isRequired
  }).isRequired
}

export default ArticlesListPage

export const pageQuery = graphql`query blogListQuery($skip: Int!, $limit: Int!) {
  allPrismicArticle(
    sort: {fields: [first_publication_date], order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        uid
        first_publication_date(formatString: "DD MMMM YYYY", locale: "fr")
        data {
          title
          subtitle
          seotitle
          seodescription
          content {
            html
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  height: 600
                  quality: 90
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
            alt
          }
        }
      }
    }
  }
}
`